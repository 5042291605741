import { motion } from 'framer-motion';
import { PageAnimationVariants, TitleAnimationVariants } from '../lib/utils/animation';
import { FunctionComponent } from 'react';

const ErrorPage: FunctionComponent = () => {
    return (
        <motion.div initial="exit" animate="enter" exit="exit">
            <section>
                <div className="hero is-small is-info">
                    <div className="container is-fluid">
                        <div className="hero-body">
                            <motion.div variants={TitleAnimationVariants}>
                                <h1 className="title is-size-8">404</h1>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
            <motion.div variants={PageAnimationVariants}>
                <div className="container page-content">
                    <h2 className="subtitle" style={{ textAlign: 'center', paddingTop: '5rem' }}>
                        Error 404 : The requested resource could not be found, sorry!
                    </h2>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default ErrorPage;
